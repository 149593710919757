




















import Vue from 'vue';
import ItemCard from "@/old/items/ItemCard.vue";
import ResponsiveCardList from "@/components/util/ResponsiveCardList.vue";
import ResponsiveCardListEntry from "@/components/util/ResponsiveCardListEntry.vue";
import ItemsProvider from '@/api/ItemsProvider';

interface IData {
    keys: [number, number][]
}

export default Vue.extend({
    components: {
        ItemCard,
        ResponsiveCardList,
        ResponsiveCardListEntry,
    },
    props: {
        itemId: {
            type: Number as () => number,
            required: true,
        },
    },
    watch: {
        itemId() {
            this.load();
        }
    },
    data(): IData {
        return {
            keys: [],
        };
    },
    mounted() {
        this.load();
    },
    methods: {
        async load() {
            this.keys = await ItemsProvider.getItemCharmRequiredKeys(this.itemId);
        }
    }
});
