import store from "@/store";

export enum FavoriteType {
    ITEM = 'item',
    SKILL = 'skill',
}

class FavoritesProvider {
    public async addFavorite(type: FavoriteType, id: string, region?: string) {
        region = this._ensureRegion(region);
        const existing = await this.getFavorites(type);
        const set = new Set<string>(existing);
        set.add(id);
        window.localStorage.setItem(`dv.ref.dn.fav.${region}.${type}`, JSON.stringify([...set]));
    }
    
    public async removeFavorite(type: FavoriteType, id: string, region?: string) {
        region = this._ensureRegion(region);
        const existing = await this.getFavorites(type);
        const set = new Set<string>(existing);
        set.delete(id);
        window.localStorage.setItem(`dv.ref.dn.fav.${region}.${type}`, JSON.stringify([...set]));
    }
    
    public async getFavorites(type: FavoriteType, region?: string): Promise<string[]> {
        region = this._ensureRegion(region);
        const item = window.localStorage.getItem(`dv.ref.dn.fav.${region}.${type}`);
        if (item == null) {
            return [];
        }
        
        try {
            const payload = JSON.parse(item);
            if (!Array.isArray(payload)) {
                throw new Error('Stored payload is not an array');
            }
            
            return payload as string[];
        } catch (e) {
            console.error(`Failed to load favorites ${type} from storage`, e);
            return [];
        }
    }

    private _ensureRegion(region?: string): string {
        if (!region) {
            return store.state.regionCode;
        }

        return region;
    }
}

export default new FavoritesProvider();
