

















import Vue from 'vue';

const FADE_OUT: anime.AnimeParams = {
    targets: "",
    opacity: "0.0",
    easing: "easeOutCubic",
    offset: 0,
};

const FADE_IN: anime.AnimeParams = {
    targets: "",
    opacity: ["0.0", "1.0"],
    easing: "easeOutCubic",
    offset: 0,
};

export default Vue.extend({
    props: {
        "copyContent": {
            type: String as () => string,
        }
    },
    computed: {
        link(): string {
            if (this.copyContent.startsWith("http://") || this.copyContent.startsWith("https://")) {
                return this.copyContent;
            }

            return "#";
        }
    },
    methods: {
        copyLink() {
            let box = this.$refs['copyLink'] as HTMLFormElement;
            box.value = this.copyContent;
            box.focus();
            box.select();
            let success = document.execCommand('copy');
            if (success) {
                this.showSuccess();
            } else {
                this.showFail();
            }
        },
        showSuccess() {
            const timeline = this.$anime.timeline();
            timeline.finished.then(() => {
                const reverse = this.$anime.timeline();
                reverse.add(this.setTarget(FADE_OUT, ".res"))
                    .add(this.setTarget(FADE_IN, ".idle"));
            });

            timeline.add(this.setTarget(FADE_OUT, ".idle"))
                .add(this.setTarget(FADE_IN, ".ok"));
            

        },
        showFail() {
            const timeline = this.$anime.timeline();

            timeline.finished.then(() => {
                const reverse = this.$anime.timeline();
                reverse.add(this.setTarget(FADE_OUT, ".res"))
                    .add(this.setTarget(FADE_IN, ".idle"));
            });

            timeline.add(this.setTarget(FADE_OUT, ".idle"))
                .add(this.setTarget(FADE_IN, ".error"));
        },
        setTarget(animParams: anime.AnimeParams, targets: string) {
            const ret = Object.assign({}, animParams);
            ret.targets = targets;
            return ret;
        },
        setDuration(animParams: anime.AnimeParams, duration: number) {
            const ret = Object.assign({}, animParams);
            ret.duration = duration;
            return ret;
        },
    },
});
