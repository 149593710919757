
















import Vue from 'vue'
import ResponsiveCardList from "@/components/util/ResponsiveCardList.vue"
import ResponsiveCardListEntry from "@/components/util/ResponsiveCardListEntry.vue"
import FavoritesProvider, { FavoriteType } from '@/api/FavoritesProvider';

interface IData {
    favorites: string[];
}

export default Vue.extend({
    components: {
        ResponsiveCardList,
        ResponsiveCardListEntry,
    },
    data(): IData {
        return {
            favorites: [],
        };
    },
    mounted() {
        this.load();
    },
    methods: {
        async load() {
            this.favorites = await FavoritesProvider.getFavorites(FavoriteType.ITEM);
        }
    }
})
