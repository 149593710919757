






























import Vue from 'vue';
import ResponsiveCardList from "@/components/util/ResponsiveCardList.vue";
import ResponsiveCardListEntry from "@/components/util/ResponsiveCardListEntry.vue";

import ItemsProvider, { PROB_MAX } from '@/api/ItemsProvider';
import IItemDropEntry from '@/models/items/IItemDropEntry';

interface IData {
    loading: boolean;
    drops: IItemDropEntry[];
    error: string;
}

export default Vue.extend({
    components: {
        ResponsiveCardList,
        ResponsiveCardListEntry,
    },
    props: {
        dropId: {
            type: Number as () => number,
        },
    },
    data(): IData {
        return {
            loading: false,
            drops: [],
            error: '',
        };
    },
    watch: {
        dropId() {
            this.load();
        }
    },
    computed: {
        nothingChance(): number {
            if (this.loading) {
                return 0;
            }

            const sum = this.drops.reduce((pv, v) => v.prob + pv, 0);
            if (sum < PROB_MAX) {
                const delta = PROB_MAX - sum;
                return delta / PROB_MAX;
            }

            return 0;
        }
    },
    mounted() {
        this.load();
    },
    methods: {
        async load() {
            try {
                this.loading = true;
                this.error = '';
                this.drops = [];
                this.drops = await ItemsProvider.getItemDrop(this.dropId);
            } catch (e) {
                this.error = String(e);
            } finally {
                this.loading = false;
            }
        }
    }
})
