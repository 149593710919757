













































import Vue from 'vue'
import ItemFavoriteList from '@/components/item/ItemFavoriteList.vue';

export default Vue.extend({
    components: {
        ItemFavoriteList,
    },
})
