














import Vue from 'vue';
export default Vue.extend({
    props: {
        "type": {
            type: String as () => string,
        },
        "icon": {
            type: String as () => string,
        },
    }
});
