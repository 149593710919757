










































import Vue from 'vue';

export default Vue.extend({
    props: {
        colorCode: {
            type: Number as () => number,
            required: true,
        },
    },
    computed: {
        diffuse(): number[] {
            const red = this.normalize(this.getDiffuseChannel(20), 8) * 255;
            const green = this.normalize(this.getDiffuseChannel(10), 8) * 255;
            const blue = this.normalize(this.getDiffuseChannel(0), 8) * 255;

            return [red, green, blue];
        },
        emissive(): number[] {
            const red = this.normalize(this.getEmissiveChannel(26), 2) * 255;
            const green = this.normalize(this.getEmissiveChannel(16), 2) * 255;
            const blue = this.normalize(this.getEmissiveChannel(6), 2) * 255;

            return [red, green, blue];
        },
        combined(): number[] {
            const dif = this.diffuse;
            const emi = this.emissive;

            const multi = 0.675;
            return [dif[0] + (emi[0] * multi), dif[1] + (emi[1] * multi), dif[2] + (emi[2] * multi)];
        },
    },
    methods: {
        asStyleString(n: number[]) {
            const values = n.map(v => this.fixed(v)).join(', ');
            return `rgb(${values})`;
        },
        asHex(n: number[]) {
            const values = n.map((v) => (~~v).toString(16).padStart(2, '0')).join('');
            return '#' + values;
        },
        getDiffuseChannel(offset: number): number {
            return this.extractBits(offset, 6);
        },
        getEmissiveChannel(offset: number): number {
            return this.extractBits(offset, 4);
        },
        extractBits(offset: number, bits: number): number {
            const baseBitMask = (1 << bits) - 1;
            const offsetBitMask = baseBitMask << offset;
            return (this.colorCode & offsetBitMask) >> offset;
        },
        normalize(value: number, bits: number): number {
            const max = (1 << bits) - 1;
            return value / max;
        },
        fixed(n: number): string {
            return n.toLocaleString(undefined, {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
            });
        }
    }
});
